import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// import SideBar from "../../components/SideBar";
import { loadActivitiesList } from "../../reducers/Activities";
import { loadMNOsList } from "../../reducers/MNOs";
import { loadORGsList } from "../../reducers/ORGs";
import Footer from "./Footer";
import Header from "./Header";
/**
 * Pages
 */
import { AiOutlineMenu } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectOrg from "../../components/SelectOrg";

import Auth from "../Auth";
import AddBanner from "../Banners/AddBanner";
import BannersList from "../Banners/BannersList";
import AddBrandEmbassador from "../BrandEmbassadors/AddBrandEmbassador";
import BrandEmbassadors from "../BrandEmbassadors/BrandEmbassadors";
import Opsmile from "../Opsmile/Opsmile.js";
import Keywords from "../Keywords/Keywords";
import NGONews from "../NGONews";
import AddNGONews from "../NGONews/AddNGONews";
import Notification from "../Notification";
import AddNotification from "../Notification/AddNotification";
import AddOrganization from "../Organizations/AddOrganization";
import Organizations from "../Organizations/Organizations";
import Projects from "../Projects";
import AddNewRamadanNote from "../RamadanNotes/AddNewRamadanNote";
import RamadanNotes from "../RamadanNotes/RamadanNotes";
import Reports from "../Reports/Reports";
import AddNewVideo from "../SelectedVideos/AddNewVideo";
import SelectedVideos from "../SelectedVideos/SelectedVideos";
import Teams from "../Teams";
import AddTeams from "../Teams/AddTeams";
import UrgentCases from "../UrgentCases";
import { validateUser } from "../../reducers/Auth.js";
import { Toaster } from "react-hot-toast";

const Main = ({
  // mobileNavVisibility,
  // hideMobileMenu,
  // history,
  loadOrgs,
  loadActivities,
  loadMNOs,
  isLoggedIn,
}) => {
  // history.listen(() => {
  //   if (mobileNavVisibility === true) {
  //     // hideMobileMenu();
  //   }
  // });
  useEffect(() => {
    if (
      localStorage.getItem("access_token") != null &&
      localStorage.getItem("userObjectId") != null
    ) {
      const token = localStorage.getItem("access_token");
      const object_id = localStorage.getItem("userObjectId");

      validateUser(token, object_id);
    }
  }, []);

  loadOrgs();
  loadActivities();
  loadMNOs();

  const [mobileMenu, setMobileMenu] = useState(false);
  const isAlreadyLoggedIn = localStorage.getItem("userloggedin");

  return (
    <div
    // className={cx({
    //   "nav-open": mobileNavVisibility === true,
    // })}
    >
      {isAlreadyLoggedIn === "false" || isAlreadyLoggedIn === null ? (
        <Auth />
      ) : (
        <div className="wrapper">
          {/* <div className="close-layer" onClick={hideMobileMenu}></div> */}
          {/* <button className="menuBtn">
            <SelectOrg />

            {!mobileMenu ? (
              <AiOutlineMenu
                onClick={() => {
                  setMobileMenu(true);
                }}
              />
            ) : (
              <IoMdClose
                onClick={() => {
                  setMobileMenu(false);
                }}
              />
            )}
          </button> */}

          {/* <SideBar mobileMenu={mobileMenu} /> */}
          <div className="main-panel">
            <Header />

            <Switch>
              <Route path="/Opsmile" component={Opsmile} />
              <Route path="/" component={Opsmile} />
              {/* <Route path="/projects" component={Projects} />
              <Route path="/urgent" component={UrgentCases} />

              <Route
                path={["/addInMemory", "/editInMemory/:id"]}
                component={AddInMemoryOf}
              />
              <Route path="/brandEmbassadors" component={BrandEmbassadors} />
              <Route path="/organizations" component={Organizations} />
              <Route path="/selectedVideos" component={SelectedVideos} />
              <Route path="/ramadanNotes" component={RamadanNotes} />

              <Route
                path={["/addNewEmbassador", "/editEmbassador/:id"]}
                component={AddBrandEmbassador}
              />

              <Route
                path={["/addNewOrganization", "/editOrganization/:id"]}
                component={AddOrganization}
              />
              <Route
                path={["/addNewVideo", "/editSelectedVideo/:id"]}
                component={AddNewVideo}
              />

              <Route
                path={["/addNewRamadanNote", "/editRamadanNote/:id"]}
                component={AddNewRamadanNote}
              />

              <Route
                path={["/addNewActivity", "/editActivity/:id"]}
                component={AddActivity}
              />

              <Route path="/banners" component={BannersList} />
              <Route
                path={["/newBanner", "/editBanner/:id"]}
                component={AddBanner}
              />

              <Route path="/ngo" component={NGONews} />
              <Route
                path={["/addNewNGONews", "/editNGONews/:id"]}
                component={AddNGONews}
              />
              <Route path="/notification" component={Notification} />
              <Route
                path={["/addNotification", "/editNotification/:id"]}
                component={AddNotification}
              />

              <Route path="/teams" component={Teams} />
              <Route
                path={["/addTeam", "/editTeam/:id"]}
                component={AddTeams}
              />
              <Route path="/reports" component={Reports} />
              <Route path="/keywords" component={Keywords} /> */}
            </Switch>
            <Footer />
          </div>
          <Toaster></Toaster>
        </div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

const mapStateToProp = (state) => ({
  // mobileNavVisibility: state.Layout.mobileNavVisibility,
  isLoggedIn: state.Auth.isLoggedIn,
  user: state.Auth,
});

const mapDispatchToProps = (dispatch) => ({
  // hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
  loadOrgs: () => dispatch(loadORGsList()),
  loadActivities: () => dispatch(loadActivitiesList()),
  loadMNOs: () => dispatch(loadMNOsList()),
  validateUser: (token, object_id) => dispatch(validateUser(token, object_id)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
