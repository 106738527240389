import React from "react";
import { FiLogOut } from "react-icons/fi";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleMobileNavVisibility } from "../../reducers/Layout";
import { setSelectedOrg } from "../../reducers/ORGs";
import { updateProjects } from "../../reducers/Projects";
import { updateCases } from "../../reducers/Cases";
import logoImage from "../../assets/images/logo-light.svg";
import { Logout } from "../../reducers/Auth";
const Header = ({ Logout }) => {
  const handleLogout = async () => {
    await Logout();
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload(false);
  };

  return (
    <div className="header navbar mobile">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0 20px",
        }}
      >
        <div className="brand">
          <a href="#" className="brand-name">
            <img src={logoImage} alt="ikhair logo" className="logo" />
          </a>
        </div>
        <button
          className="logout"
          onClick={handleLogout}
          style={{ cursor: "pointer" }}
        >
          <FiLogOut size={24} color="red" />
          Log out
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orgs: state.ORGs.orgs,
  isPending:
    state.ORGs.isPending ||
    state.Activities.isPending ||
    state.MNOs.isPending ||
    state.Projects.isPending ||
    state.Cases.isPending ||
    state.Banners.isPending ||
    state.Keywords.isPending ||
    state.Reports.isPending,
  selectedOrg: state.ORGs.selectedOrg,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
  selectOrg: (org) => dispatch(setSelectedOrg(org)),
  updateProjects: () => dispatch(updateProjects()),
  updateCases: () => dispatch(updateCases()),
  Logout: () => dispatch(Logout()),
});

Header.propTypes = {
  updateCases: PropTypes.func,
  updateProjects: PropTypes.func,
  handleLogout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
