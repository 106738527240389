/* eslint-disable no-undef */
export const config = {
  isProd: process.env.NODE_ENV === "production",
  stgApiUrl: "https://stgapi.ikhair.net",
  //stgApiUrl: "http://localhost:9000",
  //stgApiUrl: 'https://api2.ikhair.net',
  ikhair: "https://api2.ikhair.net",
  ikhairstg: "https://stgapi.ikhair.net",
  kheerom: "https://api-om.ikhair.net",
  jonation: "https://jonationapi.ikhair.net",
  prodApibagisUrl: "https://bagisapi.instabackend.io",
  kongPassword: "n7s7id63",
  appgainServer: "https://feapi.appgain.io",
  appApiKey: "b89695cf16ee70a99bba8bb1860b3b418e9b2312d6e5f6bd85542138d442eab5",
  appId: "665f2822ac04714f5aa2888e",
  appBackend: "https://appbackend.appgain.io/5864ef5223fcb95c7a99a156/ikhair",
};
export const getServerUrl = () => config.ikhair;
